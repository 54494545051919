<template>
  <BInputWithValidation
    :id="`prelimInput_${field.identifier}`"
    v-if="visible"
    :rules="{ required: true, regex: /^[0-9 ]*$/ }"
    :label="field.label"
    :mask="field.mask"
    :validation="field.validation"
    v-model="selectedValue"
  />
</template>

<script>
export default {
  name: "PreliminaryInputQuestion",
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    mortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: "",
    };
  },
  mounted() {
    this.selectedValue =
      this.$store.getters[
        this.mortgage
          ? "mortgagePreliminaryAssessment/answer"
          : "preliminaryAssessment/answer"
      ](this.field.identifier) || null;
    this.$watch("selectedValue", this.valueWatcher);
  },
  methods: {
    valueWatcher() {
      this.$store.dispatch(
        this.mortgage
          ? "mortgagePreliminaryAssessment/addData"
          : "preliminaryAssessment/addData",
        {
          question: this.field.identifier,
          answer: this.selectedValue,
        }
      );
    },
  },
};
</script>

<style scoped></style>
